<script>
import { SuawMainContent } from "@suaw/suaw-component-library";
import HeroSection from "./components/HeroSection.vue";
import WelcomeSection from "./components/WelcomeSection.vue";
import HowSection from "./components/HowSection.vue";
import WritingPartnersSection from "./components/WritingPartnersSection.vue";
import WhySection from "./components/WhySection.vue";
import TestimonialSection from "./components/TestimonialSection.vue";
import OrganizeSection from "./components/OrganizeSection.vue";
import JoinSection from "./components/JoinSection.vue";
import NewsletterSection from "./components/NewsletterSection.vue";
export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Writing Community - ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Free in-person and online writing groups for writers all over the world. Get access to the community and resources you need to be successful."
      }
    ]
  },
  components: {
    SuawMainContent,
    HeroSection,
    WelcomeSection,
    HowSection,
    WritingPartnersSection,
    WhySection,
    TestimonialSection,
    OrganizeSection,
    JoinSection,
    NewsletterSection
  },
  // metaInfo: {
  //   // if no subcomponents specify a metaInfo.title, this title will be used
  //   title: "Writing Community - ",
  //   meta: [
  //     {
  //       vmid: "description",
  //       name: "description",
  //       content: "Free in-person and online writing groups for writers all over the world. Get access to the community and resources you need to be successful."
  //     }
  //   ]
  // },
  methods: {
    onJoinClick() {
      this.$router.push({
        name: "SignIn",
        query: {
          tab: "signup"
        }
      });
    },
    onLearnMoreClick() {
      this.$router.push({
        name: "Resources-About"
      });
    },
    onSupportOurCauseClick() {
      window.open("https://www.writingpartners.org/donate", "_blank");
    },
    onFindClick() {
      this.$router.push({
        name: "ChapterDiscovery"
      });
    },
    onBecomeOrganizerClick() {
      this.$router.push({
        name: "Resources-BecomeAnOrganizer"
      });
    }
  }
};
</script>

<template>
  <main>
    <SuawMainContent class="suaw-home" size="hero">
      <HeroSection @click="onJoinClick" />
      <WelcomeSection @click="onLearnMoreClick" />
      <HowSection @click="onJoinClick" />
      <WritingPartnersSection @click="onSupportOurCauseClick" />
      <WhySection @click-find="onFindClick" @click-become="onBecomeOrganizerClick" />
      <TestimonialSection />
      <OrganizeSection @click="onBecomeOrganizerClick" />
      <JoinSection @click="onJoinClick" />
      <NewsletterSection />
    </SuawMainContent>
  </main>
</template>

<style lang="scss" scoped>
.suaw-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
